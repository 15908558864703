var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CDataTable',{staticClass:"table-members",attrs:{"items":_vm.members,"fixed":"","hover":"","striped":"","bordered":"","pagination":"","itemsPerPage":_vm.limit,"fields":_vm.fields,"sorter":_vm.sorter},on:{"update:sorter-value":_vm.onChangeSort},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1 + (_vm.activePage - 1) * _vm.limit))])]}},{key:"first_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.first_name || '-'))])]}},{key:"last_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.last_name || '-'))])]}},{key:"kyc_level",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.kyc_level))])]}},{key:"membership_type",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingBadge',{staticClass:"no-border",attrs:{"type":"membership-type","text":item.membership_type}})],1)]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{on:{"click":function($event){item.status.toLowerCase().replace(' ', '-') == 'verify-payment' ? _vm.verifyPayment(item.latest_membership_order_id) : null}}},[_c('StakingBadge',{class:item.status ? item.status.toLowerCase().replace(' ', '-') : '',attrs:{"type":"member-status","text":item.status}})],1)]}},{key:"kyc_status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingBadge',{attrs:{"type":"kyc-status","text":item.kyc_status}})],1)]}},{key:"country",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.country || '-'))])]}},{key:"referral_code",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.referral_code || '-'))])]}},{key:"referrer_code",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.referrer_code))])]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,'YYYY-MM-DD HH:mm')))])]}},{key:"latest_login_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("moment")(item.latest_login_at,'YYYY-MM-DD HH:mm')))])]}},{key:"has_ios",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.has_ios)?_c('CIcon',{staticClass:"fixed-rate-icon",attrs:{"name":"cil-check"}}):_vm._e()],1)]}},{key:"has_android",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.has_android)?_c('CIcon',{staticClass:"fixed-rate-icon",attrs:{"name":"cil-check"}}):_vm._e()],1)]}},{key:"is_testing_account",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.is_testing_account)?_c('CIcon',{staticClass:"fixed-rate-icon",attrs:{"name":"cil-check"}}):_vm._e()],1)]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',[(_vm.isAllowedUpdateMember)?_c('button',{staticClass:"edit-member-btn",on:{"click":function($event){return _vm.editMember(item.id)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-edit-user.svg"),"alt":""}})]):_vm._e(),(_vm.isAllowedViewRefferalStructure)?_c('button',{staticClass:"edit-member-btn",on:{"click":function($event){return _vm.viewReferral(item.id)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/structure-tree.svg"),"alt":""}})]):_vm._e()])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }