<template>
  <div>
    <CCard>
      <CDataTable
        :items="members"
        fixed
        hover
        striped
        bordered
        pagination
        :itemsPerPage="limit"
        class="table-members"
        :fields="fields"
        :sorter="sorter"
        @update:sorter-value="onChangeSort"
      >
        <template ##="{ item, index }">
          <td>{{ index + 1 + (activePage - 1) * limit }}</td>
        </template>

        <template #first_name="{ item }">
          <td>{{ item.first_name || '-' }}</td>
        </template>

        <template #last_name="{ item }">
          <td>{{ item.last_name || '-' }}</td>
        </template>

        <template #kyc_level="{ item }">
          <td>{{ item.kyc_level }}</td>
        </template>
        <template #membership_type="{ item }">
          <td>
            <StakingBadge class="no-border" type="membership-type" :text="item.membership_type" />
          </td>
        </template>

        <template #status="{ item }">
          <td
            @click="item.status.toLowerCase().replace(' ', '-') == 'verify-payment' ? verifyPayment(item.latest_membership_order_id) : null"
          >
            <StakingBadge
              type="member-status"
              :text="item.status"
              :class="item.status ? item.status.toLowerCase().replace(' ', '-') : ''"
            />
          </td>
        </template>
        <template #kyc_status="{ item }">
          <td>
            <StakingBadge type="kyc-status" :text="item.kyc_status" />
          </td>
        </template>
        <template #country="{ item }">
          <td>{{ item.country || '-' }}</td>
        </template>
        <template #referral_code="{ item }">
          <td>{{ item.referral_code || '-' }}</td>
        </template>
        <template #referrer_code="{ item }">
          <td>{{ item.referrer_code }}</td>
        </template>
        <template #created_at="{ item }">
          <td>{{ item.created_at | moment('YYYY-MM-DD HH:mm') }}</td>
        </template>
        <template #latest_login_at="{ item }">
          <td>{{ item.latest_login_at | moment('YYYY-MM-DD HH:mm') }}</td>
        </template>
        <template #has_ios="{ item }">
          <td>
            <CIcon v-if="item.has_ios" class="fixed-rate-icon" name="cil-check" />
          </td>
        </template>
        <template #has_android="{ item }">
          <td>
            <CIcon v-if="item.has_android" class="fixed-rate-icon" name="cil-check" />
          </td>
        </template>
         <template #is_testing_account="{ item }">
          <td>
            <CIcon v-if="item.is_testing_account" class="fixed-rate-icon" name="cil-check" />
          </td>
        </template>
        <template #action="{ item }">
          <td>
            <button v-if="isAllowedUpdateMember" class="edit-member-btn" @click="editMember(item.id)">
              <img src="@/assets/images/ico-edit-user.svg" alt class="hand edit-icon" />
            </button>
            <button v-if="isAllowedViewRefferalStructure" class="edit-member-btn" @click="viewReferral(item.id)">
              <img src="@/assets/images/structure-tree.svg" alt class="hand edit-icon" />
            </button>
          </td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { env } from '@/constants';
import StakingBadge from '@/components/StakingBadge';

export default {
  name: 'ListMemberTable',
  components: {
    StakingBadge,
  },
  data() {
    return {
      fields: [
        {
          key: '#',
          label: '#',
          sorter: false,
        },
        {
          key: 'last_name',
          label: this.$t('MEMBER_TABLE_COMPONENT_COL_LAST_NAME'),
          sorter: false,
          _style: 'min-width: 100px',
        },
        {
          key: 'first_name',
          label: this.$t('MEMBER_TABLE_COMPONENT_COL_FIRST_NAME'),
          _style: 'min-width: 100px',
          sorter: false,
        },
        {
          key: 'email',
          label: this.$t('MEMBER_TABLE_COMPONENT_COL_EMAIL'),
          sorter: false,
        },
        {
          key: 'kyc_level',
          label: this.$t('MEMBER_TABLE_COMPONENT_COL_KYC'),
          sorter: false,
        },
        {
          key: 'kyc_status',
          label: this.$t('MEMBER_TABLE_COMPONENT_COL_KYC_STATUS'),
          sorter: false,
          _style: 'min-width: 150px',
        },
        {
          key: 'country',
          label: this.$t('MEMBER_TABLE_COMPONENT_COL_COUNTRY'),
          sorter: false,
          _style: 'min-width: 150px',
        },
        {
          key: 'membership_type',
          label: this.$t('MEMBER_TABLE_COMPONENT_COL_MEMBERSHIP'),
          sorter: false,
        },
        {
          key: 'status',
          label: this.$t('MEMBER_TABLE_COMPONENT_LABEL_STATUS'),
          sorter: false,
          _style: 'min-width: 135px',
        },
        {
          key: 'referral_code',
          label: this.$t('MEMBER_TABLE_COMPONENT_COL_REFERRAL_CODE'),
          sorter: false,
        },
        {
          key: 'referrer_code',
          label: this.$t('MEMBER_TABLE_COMPONENT_COL_REFERRER'),
          sorter: true,
        },
        {
          key: 'latest_login_at',
          label: this.$t('MEMBER_TABLE_COMPONENT_COL_LATEST_LOGIN_AT'),
          _style: 'min-width: 150px',
          sorter: false,
        },
        {
          key: 'has_ios',
          label: this.$t('MEMBER_TABLE_COMPONENT_COL_HAS_IOS'),
          _style: 'min-width: 50px; text-align: left;',
          sorter: false,
        },
        {
          key: 'has_android',
          label: this.$t('MEMBER_TABLE_COMPONENT_COL_HAS_ANDROID'),
          _style: 'min-width: 50px; text-align: left;',
          sorter: false,
        },
         {
          key: 'is_testing_account',
          label: this.$t('MEMBER_TABLE_COMPONENT_COL_TEST_ACCOUNT'),
          sorter: false,
        },
        {
          key: 'created_at',
          label: this.$t('MEMBER_TABLE_COMPONENT_COL_JOINED'),
          _style: 'min-width: 150px',
          sorter: false,
        },
        {
          key: 'action',
          label: '',
          sorter: false,
          _style: 'min-width: 100px',
        },
      ],
      sorter: {
        external: true,
        resetable: false,
      },
      isAllowedUpdateMember: false,
      isAllowedViewRefferalStructure: false,
    };
  },
  props: {
    members: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 999,
    },
    changeSort: {
      type: Function,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  mounted() {
    this.checkPermissions();
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach((items) => {
          if (items.name === 'MEMBERSHIP_UPDATE_MEMBER') {
            this.isAllowedUpdateMember = true;
          }
          if (items.name === 'MEMBERSHIP_VIEW_REFERRAL_STRUCTURE') {
            this.isAllowedViewRefferalStructure = true;
          }
        });
      }
    },
    onChangeSort(sorterValue) {
      this.changeSort(sorterValue);
    },
    editMember(memberId) {
      this.$router.push(`members/${memberId}/details`);
    },
    viewReferral(memberId) {
      this.$router.push(`members/${memberId}/referral`);
    },
    verifyPayment(id) {
      this.$router.push(`membership-orders/${id}/details`);
    },
  },
};
</script>

<style lang="scss">
.member-list-container {
  .table-members {
    tbody {
      td {
        color: #4d5666;
        > span {
          background-color: unset;
          padding: 0;
          &.verify-payment {
            color: #fff;
            background-color: #5da30b;
            padding: 4px 12px;
            border-radius: 19px;
            font-size: 12px;
            cursor: pointer;
          }
          &.deactivated {
            color: #ff0000;
          }
          &.active {
            color: #5da30b;
          }
        }
      }
    }
  }
  .edit-member-btn {
    font-size: 14px;
    font-weight: 400;
    color: #2b7ef1;
    outline: none;
    svg {
      width: 22px;
      height: 22px;
      font-size: 22px;
    }
  }
}
</style>
