<template>
  <CRow class="member-login-content">
    <CCol lg="3" sm="6" md="6" class="member-login-box">
      <div class="member-online-body">
        <div class="member-online-time">
          <span class="in-label">{{ $t('MEMBER_LOGIN_COMPONENT_IN_LABEL') }}</span>
          <div class="member-login-date">
            <span class="date-num-label">1 </span>
            <span class="date-type-label">{{ $t('MEMBER_LOGIN_COMPONENT_DAY_LABEL') }}</span>
          </div>
        </div>
        <div class="member-online-data">
          <span class="number-label">{{ _formatNumber(memberLogin.day) }}</span>
          <div class="active-member-label">{{ $t('MEMBER_LOGIN_COMPONENT_ACTIVE_MEMBERS_LABEL') }}</div>
        </div>
      </div>
    </CCol>
    <CCol lg="3" sm="6" md="6" class="member-login-box">
      <div class="member-online-body">
        <div class="member-online-time">
          <span class="in-label">{{ $t('MEMBER_LOGIN_COMPONENT_IN_LABEL') }}</span>
          <div class="member-login-date">
            <span class="date-num-label">7 </span>
            <span class="date-type-label">{{ $t('MEMBER_LOGIN_COMPONENT_DAYS_LABEL') }}</span>
          </div>
        </div>
        <div class="member-online-data">
          <span class="number-label">{{ _formatNumber(memberLogin.week) }}</span>
          <div class="active-member-label">{{ $t('MEMBER_LOGIN_COMPONENT_ACTIVE_MEMBERS_LABEL') }}</div>
        </div>
      </div>
    </CCol>
    <CCol lg="3" sm="6" md="6" class="member-login-box">
      <div class="member-online-body">
        <div class="member-online-time">
          {{ $t('MEMBER_LOGIN_COMPONENT_IN_LABEL') }}
          <div class="member-login-date">
            <span class="date-num-label">30 </span>
            <span class="date-type-label">{{ $t('MEMBER_LOGIN_COMPONENT_DAYS_LABEL') }}</span>
          </div>
        </div>
        <div class="member-online-data">
          <span class="number-label">{{ _formatNumber(memberLogin.month) }}</span>
          <div class="active-member-label">{{ $t('MEMBER_LOGIN_COMPONENT_ACTIVE_MEMBERS_LABEL') }}</div>
        </div>
      </div>
    </CCol>
    <CCol lg="3" sm="6" md="6" class="member-login-box">
      <div class="member-online-body">
        <div class="member-online-time">
          {{ $t('MEMBER_LOGIN_COMPONENT_IN_LABEL') }}
          <div class="member-login-date">
            <span class="date-num-label">90 </span>
            <span class="date-type-label">{{ $t('MEMBER_LOGIN_COMPONENT_DAYS_LABEL') }}</span>
          </div>
        </div>
        <div class="member-online-data">
          <span class="number-label">{{ _formatNumber(memberLogin.three_month) }}</span>
          <div class="active-member-label"><span>{{ $t('MEMBER_LOGIN_COMPONENT_ACTIVE_MEMBERS_LABEL') }}</span></div>
        </div>
      </div>
    </CCol>
  </CRow>
</template>
<script>
export default {
  name: 'MemberLogin',
  data() {
    return {};
  },
  props: {
    memberLogin: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    _formatNumber(value) {
      if (!value) {
        return 0;
      }
      var formatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 5,
        minimumFractionDigits: 0,
      }).format(value);
      return formatter;
    },
  },
};
</script>
<style lang="scss">
  .member-login-content {
    padding: 15px 15px 10px 15px;
    display: flex;
    justify-content: space-between;
    .member-login-box {
        max-width: 24%;
        padding: 0px;
      @media (max-width: 992px) {
        max-width: 50%;
        padding: 5px;
      }
      .member-online-body {
        background: #ffffff;
        border-left: 4px solid #3b4b7e;
        border-right: 1px solid #dadde1;
        border-top: 1px solid #dadde1;
        border-bottom: 1px solid #dadde1;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        line-height: 14px;
        color: #C0C0C0;
        height: 100%;

        .member-online-time {
          padding: 10px 0 10px 10px;
          width: 60px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .member-login-date {
            .date-num-label {
              color:  #8A93A2;
              font-weight: 600;
            }

            .date-type-label {
              color:  #8A93A2;
              font-size: 16px;
            }
          }
        }

        .member-online-data {
          padding-right: 10px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-wrap: wrap;
          .number-label {
            color:  #8A93A2;
            font-size: 36px;
            line-height: 48px;
            margin-right: 10px;
          }

          .active-member-label {
            max-width: 50px;
          }
        }
      }
    }
  }
</style>
