<template>
  <div class="form-search">
    <div class="flex1">
      <label>{{ $t('MEMBER_SEARCH_COMPONENT_TITLE') }}</label>
    </div>
    <ul class="row group-fields">
      <li class="col-md-3 col-sm-12">
        <ValidationProvider name="MEMBER_SEARCH_COMPONENT_LABEL_LAST_NAME" v-slot="{ errors }" rules>
          <CInput
            :label="$t('MEMBER_SEARCH_COMPONENT_LABEL_LAST_NAME')"
            type="text"
            v-model="searchData.lastName"
            class="form-group"
            @keyup.enter="search(searchData)"
          />
          <CRow>
            <CCol :md="12">
              <span class="error-msg mt-1">{{ errors[0] }}</span>
            </CCol>
          </CRow>
        </ValidationProvider>
      </li>

      <li class="col-md-3 col-sm-12">
        <ValidationProvider name="First Name" v-slot="{ errors }" rules>
          <CInput
            :label="$t('MEMBER_SEARCH_COMPONENT_LABEL_FIRST_NAME')"
            type="text"
            v-model="searchData.firstName"
            class="form-group"
            @keyup.enter="search(searchData)"
          />
          <CRow>
            <CCol :md="12">
              <span class="error-msg mt-1">{{ errors[0] }}</span>
            </CCol>
          </CRow>
        </ValidationProvider>
      </li>

      <li class="col-md-3 col-sm-12">
        <ValidationProvider name="MEMBER_SEARCH_COMPONENT_LABEL_EMAIL" v-slot="{ errors }" rules>
          <CInput
            :label="$t('MEMBER_SEARCH_COMPONENT_LABEL_EMAIL')"
            type="text"
            v-model="searchData.email"
            class="form-group"
            @keyup.enter="search(searchData)"
          />
          <CRow>
            <CCol :md="12">
              <span class="error-msg mt-1">{{ errors[0] }}</span>
            </CCol>
          </CRow>
        </ValidationProvider>
      </li>
      <li class="col-md-3 col-sm-12">
        <CSelect :label="$t('MEMBER_SEARCH_COMPONENT_LABEL_KYC')" :value.sync="searchData.kyc_level" :options="kycOpyions" />
      </li>
      <li class="col-md-3 col-sm-12">
        <CSelect :label="$t('MEMBER_SEARCH_COMPONENT_LABEL_KYC_STATUS')" :value.sync="searchData.kycStatus" :options="kycStatuses" />
      </li>
      <li class="col-md-3 col-sm-12">
        <CSelect :label="$t('MEMBER_SEARCH_COMPONENT_LABEL_MEMBERSHIP')" :value.sync="searchData.membershipTypeId" :options="options" />
      </li>
      <li class="col-md-3 col-sm-12">
        <CSelect :label="$t('MEMBER_SEARCH_COMPONENT_LABEL_STATUS')" :value.sync="searchData.status" :options="memberStatuses" />
      </li>
      <li class="col-md-3 col-sm-12">
        <ValidationProvider name="MEMBER_SEARCH_COMPONENT_LABEL_REFERRAL_CODE" v-slot="{ errors }" rules>
          <CInput
            :label="$t('MEMBER_SEARCH_COMPONENT_LABEL_REFERRAL_CODE')"
            type="text"
            v-model="searchData.referralCode"
            class="form-group"
            @keyup.enter="search(searchData)"
          />
          <CRow>
            <CCol>
              <span class="error-msg mt-1">{{ errors[0] }}</span>
            </CCol>
          </CRow>
        </ValidationProvider>
      </li>
      <li class="col-md-3 col-sm-12">
        <ValidationProvider name="MEMBER_SEARCH_COMPONENT_LABEL_REFERRER" v-slot="{ errors }" rules>
          <CInput
            :label="$t('MEMBER_SEARCH_COMPONENT_LABEL_REFERRER')"
            type="text"
            v-model="searchData.referrer"
            class="form-group"
            @keyup.enter="search(searchData)"
          />
          <CRow>
            <CCol>
              <span class="error-msg mt-1">{{ errors[0] }}</span>
            </CCol>
          </CRow>
        </ValidationProvider>
      </li>
      <li class="col-md-3 col-sm-12" v-if="!this.userCountryCode">
        <CSelect :label="$t('MEMBER_SEARCH_COMPONENT_LABEL_COUNTRY')" :value.sync="searchData.country" :options="countries" />
      </li>

      <li class="col-md-3 col-sm-12">
        <div role="group" class="form-group">
          <label class>{{ $t('SEARCH_CRYPTO_ADDRESS_HEADER_COMPONENT_HAS_IOS_LABEL') }}</label>
          <div class="btn-switch-wrapper">
            <label class="btn-switch">
              <input type="checkbox" class="checkbox" v-model="searchData.hasIos" />
              <span class="check-silder"></span>
            </label>
          </div>
        </div>
      </li>
      <li class="col-md-3 col-sm-12">
        <div role="group" class="form-group">
          <label class>{{ $t('SEARCH_CRYPTO_ADDRESS_HEADER_COMPONENT_HAS_ANDROID_LABEL') }}</label>
          <div class="btn-switch-wrapper">
            <label class="btn-switch">
              <input type="checkbox" class="checkbox" v-model="searchData.hasAndroid" />
              <span class="check-silder"></span>
            </label>
          </div>
        </div>
      </li>
    </ul>
    <div class="group-button-search">
      <div class="col-md-3 col-sm-12 custom-btn-white">
        <CButton @click="downloadCSV()">{{ $t('MEMBER_SEARCH_COMPONENT_BUTTON_DOWNLOAD_CSV') }}</CButton>
      </div>
      <div class="col-md-3 btn-disabled"></div>
      <div class="col-md-3 col-sm-12 custom-btn-white">
        <CButton @click="resetForm()">{{ $t('MEMBER_SEARCH_COMPONENT_BUTTON_RESET') }}</CButton>
      </div>
      <div class="col-md-3 col-sm-12 btn-search">
        <CButton @click="search()">{{ $t('MEMBER_SEARCH_COMPONENT_BUTTON_SEARCH') }}</CButton>
      </div>
    </div>
  </div>
</template>

<script>
import sessionStorageHelper from '@/utils/sessionStorage-helper';
import { mapGetters } from 'vuex';
export default {
  name: 'SearchMembers',

  data() {
    return {
      searchData: sessionStorageHelper.getData('filter-members') || this.getValueDefaultSearchMembers(),
    };
  },
  props: {
    onSearch: {
      type: Function,
      default: null,
    },
    downloadCSV: {
      type: Function,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    memberStatuses: {
      type: Array,
      default: () => [],
    },
    kycOpyions: {
      type: Array,
      default: () => [],
    },
    kycStatuses: {
      type: Array,
      default: () => [],
    },
    countries: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(['userCountryCode']),
  },
  methods: {
    getValueDefaultSearchMembers() {
      return {
        firstName: '',
        lastName: '',
        email: '',
        membershipTypeId: '',
        referralCode: '',
        referrer: '',
        status: '',
        kyc_level: '',
        kycStatus: '',
        country: '',
        hasWeb: false,
        hasIos: false,
        hasAndroid: false,
      };
    },
    resetForm() {
      this.searchData = this.getValueDefaultSearchMembers();
      this.search();
    },
    async search() {
      sessionStorageHelper.setData('filter-members', this.searchData);

      const searchData = {
        ...this.searchData,
      };

      await this.onSearch(searchData);
    },
  },
};
</script>

<style lang="scss">
@mixin member-btn {
  width: 100%;
  color: #1a2958;
  font-size: 14px;
  line-height: 32px;
  background: #fff;
  border: 1px solid #1a2958;
  border-radius: 32px;
  height: 32px;
  padding: 0 25px;
  text-align: center;
  min-width: 150px;
  padding: 0;
  cursor: pointer;
}
.form-search {
  .group-button-search {
    display: flex;
    flex: 0 0 100%;
    flex-wrap: wrap;
    padding: 0;
    margin: 15px -15px;
    .custom-btn-white {
      margin-bottom: 15px;
      button {
        @include member-btn;
      }
    }
    .btn-search {
      button {
        display: inline-block;
        background: #3b4b7e;
        border: none;
        border-radius: 32px;
        width: 100%;
        height: 32px;
        color: #fff;
        margin: 0;
      }
    }
    .btn-disabled {
      @media only screen and( max-width: 767px ) {
        display: none;
      }
    }
  }
}
</style>
