<template>
  <div class="member-list-container">
    <MemberLogin v-if="isAllowedViewMemberLogin" :memberLogin="memberLogin" />
    <CCard>
      <CCardBody>
        <SearchMemberComponent
          :onSearch="onSearch"
          :downloadCSV="downloadCSV"
          :options="options"
          :memberStatuses="memberStatuses"
          :kycOpyions="kycOpyions"
          :kycStatuses="kycStatuses"
          :countries="countries"
        />
        <ListMemberTableComponent
          :limit="limit"
          :activePage="activePage"
          :members="members"
          :total="total"
          :changeSort="changeSort"
        />
        <StakingPagination
          :limit="limit"
          :total="total"
          :activePage="activePage"
          @changePage="onChangePage"
          @changePageSize="onChangePageSize"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import _ from 'lodash';
import MemberLogin from '@/components/Member/MemberLogin';
import ListMemberTableComponent from '@/components/Member/ListMemberTableComponent';
import SearchMemberComponent from '@/components/Member/SearchMemberComponent';
import StakingPagination from '@/components/StakingPagination';
import { endpoints, env } from '@/constants';
import { mapState, mapActions, mapGetters } from 'vuex';
import responseHelper from '@/utils/response-helper';
import { saveAs } from 'file-saver';
import stringHelper from '@/utils/string-helper';
import sessionStorageHelper from '@/utils/sessionStorage-helper';

export default {
  name: 'ListMember',
  components: {
    MemberLogin,
    ListMemberTableComponent,
    SearchMemberComponent,
    StakingPagination,
  },
  data() {
    return {
      memberLogin: {},
      members: [],
      options: [],
      memberStatuses: [],
      kycOpyions: [],
      kycStatuses: [],
      countries: [],
      searchData: {},
      email: '',
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
      pageInput: 1,
      putActivePage: 1,
      sortKey: '',
      isAllowedViewMemberLogin: false,
    };
  },
  async mounted() {
    this.onSearch();
    this.checkPermissions();
    if (this.isAllowedViewMemberLogin) {
      this.memberLogin = await this.getMemberLogin();
    }
    this.options = await this.getMembershipType();
    this.memberStatuses = await this.getMemberStatuses();
    this.kycOpyions = await this.getListKYC();
    this.kycStatuses = await this.getKycStatuses();
    this.countries = await this.getListCountry();
  },
  computed: {
    ...mapGetters(['locale', 'userPermissions']),
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  methods: {
    ...mapActions(['getMembershipType']),
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach((items) => {
          if (items.name === 'VIEW_MEMBER_LOGIN') {
            this.isAllowedViewMemberLogin = true;
          }
        });
      }
    },
    async getMemberLogin() {
      try {
        const res = await this.$http.get(endpoints.getMemberLogin);
        return res.data;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: responseHelper.getErrorMessage(err),
        });
      }
    },
    async fetchMembers(data) {
      data = data || {};
      this.searchData = {
        ...this.searchData,
        ...data,
      };
      const searchData = {
        ...this.searchData,
      };

      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
          lastName: searchData.lastName,
          firstName: searchData.firstName,
          email: searchData.email,
          membershipTypeId: this.getValueAllOption(searchData.membershipTypeId),
          status: this.getValueAllOption(searchData.status),
          kycLevel: this.getValueAllOption(searchData.kyc_level),
          referralCode: searchData.referralCode,
          referrerCode: searchData.referrer,
          kycStatus: this.getValueAllOption(searchData.kycStatus),
          country: this.getValueAllOption(searchData.country),
          hasWeb: searchData.hasWeb,
          hasIos: searchData.hasIos,
          hasAndroid: searchData.hasAndroid,
        };
        if (this.sortKey != '' || this.sortKey != undefined) {
          params.order_by = this.sortKey;
        }
        const result = await this.$http.get(endpoints.getMembers, { params });
        this.members = result.data.items || [];
        this.members.forEach((item) => {
          item.referrer_code = item.referrer_code || '-';
        });
        this.total = result.data.total;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
          duration: 2000,
        });
      }
    },
    getValueAllOption(val) {
      if (val == 'all') {
        return '';
      } else {
        return val;
      }
    },
    async onSearch(searchData) {
      const data = searchData || sessionStorageHelper.getData('filter-members');
      this.activePage = 1;
      this.fetchMembers(data);
    },
    async onChangePage(page) {
      this.activePage = page;

      this.fetchMembers();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      this.fetchMembers();
    },
    changeSort(sorterValue) {
      if (sorterValue.asc && sorterValue.column) {
        this.sortKey = '-referrer_code';
      } else if (!sorterValue.asc && sorterValue.column) {
        this.sortKey = 'referrer_code';
      } else {
        this.sortKey = '';
      }
      this.fetchMembers();
    },
    async getMemberStatuses() {
      try {
        const res = await this.$http.get(endpoints.getMemberStatuses);
        let options = res.data;
        options = stringHelper.translateTextForDropdownList(options, 'MEMBER-STATUS');
        options.unshift({ label: this.$t('MEMBER_SEARCH_COMPONENT_OPTION_ALL'), value: 'all' });

        return options;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getKycStatuses() {
      try {
        const res = await this.$http.get(endpoints.getKycStatuses);
        let options = res.data;
        options = stringHelper.translateTextForDropdownList(options, 'KYC_STATUS');
        options.unshift({ label: this.$t('KYC_STATUS_ALL'), value: 'all' });

        return options;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getListKYC() {
      try {
        const res = await this.$http.get(endpoints.getListKYC);
        let options = res.data;
        options = stringHelper.translateTextForDropdownList(options, 'KYC_LEVEL');
        options.unshift({ label: this.$t('MEMBER_SEARCH_COMPONENT_OPTION_ALL'), value: 'all' });
        return options;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getListCountry() {
      try {
        const res = await this.$http.get(endpoints.getListCountry);
        const options = res.data;
        options.unshift({ label: this.$t('COUNTRY_ALL'), value: 'all' });
        return options;
      } catch (error) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: responseHelper.getErrorMessage(error),
        });
      }
    },
    async downloadCSV() {
      const data = {
        ...this.searchData,
      };
      const params = {
        timezone_offset: new Date().getTimezoneOffset(),
        limit: this.limit,
        offset: this.offset,
        lastName: data.lastName || '',
        firstName: data.firstName || '',
        email: data.email || '',
        referralCode: data.referralCode || '',
        referrerCode: data.referrer || '',
        membershipTypeId: this.getValueAllOption(data.membershipTypeId),
        status: this.getValueAllOption(data.status),
        kycLevel: this.getValueAllOption(data.kyc_level),
        kycStatus: this.getValueAllOption(data.kycStatus),
        country: this.getValueAllOption(data.country),
        current_language: this.locale,
        hasWeb: data.hasWeb,
        hasIos: data.hasIos,
        hasAndroid: data.hasAndroid,
      };
      try {
        const result = await this.$http.get(endpoints.dowloadCSVMember, {
          params,
          timeout: 15 * 60 * 1000,
        });
        const data = result;
        const fileUrl = 'members.csv';
        const contentType = 'application/octet-stream';
        const file = new Blob([data], { type: contentType });
        saveAs(file, fileUrl);
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.member-list-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }
  .form-search {
    margin-bottom: 30px;
    .flex1 {
      label {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 8px;
      }
    }
  }
  .group-fields {
    display: flex;
    flex: 0 0 100%;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
  }
  label {
    display: inline-block;
  }
  .form-group {
    margin-top: 10px;
    margin-bottom: 5px;
    .col-sm-9 {
      input {
        display: inline-block;
        width: 30%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        margin: 0 0 15px 15px;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        background-clip: padding-box;
        border: 1px solid;
        color: #768192;
        background-color: #fff;
        border-color: #d8dbe0;
        border-radius: 0.25rem;
        -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      }
    }
  }
  .edit-member-btn {
    background-color: unset;
    border: none;
    outline: none;
  }
  .error-msg {
    color: #ff0000;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
  }
} //end #member_list
</style>
